import React from 'react';

import { Box } from '@core';
import { Comparison } from '@containers';

const ComparisonSection = ({ title, description, padding }) => (
  <Box {...padding}>
    <Comparison title={title} description={description} />
  </Box>
);

export default ComparisonSection;
